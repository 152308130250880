import { Flex, Text } from "theme-ui";
import { SearchBar } from "../Components/SearchBar";
import { Header } from "../Components/Header";
import { Device } from "../models";
import { StepWizardChildProps } from "react-step-wizard";
import { useState } from "react";

interface DeviceProps extends StepWizardChildProps {
  onDeviceSelect: (device: Device) => void;
  onStartAuth: (device_slug: string) => Promise<void>;
  devices: Device[];
  onClose: () => void;
  isMobile: boolean;
}

export const DevicePage: React.FunctionComponent<Partial<DeviceProps>> = ({
  onDeviceSelect,
  onStartAuth,
  devices,
  onClose,
  isMobile,
  ...props
}) => {
  const handleDeviceSelect = async (device: Device) => {
    onDeviceSelect(device);
    if (device.auth_type === "oauth" || device.auth_type === "team_oauth") {
      try {
        await onStartAuth(device.slug);
      } catch (e) {
        props.goToNamedStep("state");
      }

      window.open(device.oauth_url, "_blank");
      props.goToNamedStep("state");

    } else if (device.auth_type === "password") {
      props.goToNamedStep("password");

    } else {
      props.goToNamedStep("email");
    }
  };

  return (
    <Flex
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        height: ["100vh", "100%"],
        width: "100%",
      }}
      pt={4}
    >
      <Header
        showBack={true}
        onBack={() => props.previousStep()}
        onClose={onClose}
        hideExitButton={isMobile}
      />
      <Text sx={{ fontSize: 3, fontWeight: 600, mt: 10, px: 4 }}>
        Select your device
      </Text>
      <SearchBar
        onClick={handleDeviceSelect}
        items={devices ? devices : []}
        isMobile={isMobile}
      />
    </Flex>
  );
};
